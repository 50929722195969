@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


body{
 font-family:"Zilla Slab",sans-serif;
 font-weight:400;
 margin:0;
 height:100vh;
 width:100vw;
 display:grid;
 grid-template-areas:
  "h"
  "m"
  "f";
  grid-template-rows:min-content 1fr min-content;
  grid-template-columns: 1fr;
  
}
header {
 grid-area:h;
 padding:20px 0;
 display:flex;
 padding-left:5%;
}
header > h1 {
 margin-left:2%;
}
footer {
 grid-area:f;
 padding:20px 0;
 text-align:center;
}
main {
 grid-area:m;
/*overflow:auto;*/
display:flex;
justify-content:space-around;
flex-wrap:wrap;
}
.eo {
width:30vw;
height:30vw;
margin:10px;
background-color:red;
}
#summary_table{
 width:50vh;
 min-width:20em;
 min-height:10px;
 
/*background-color:red;*/
}

a:link,a:visited{
color:inherit;
}
