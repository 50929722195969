@import "https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap";
body {
  grid-template: "h" min-content
                 "m" 1fr
                 "f" min-content
                 / 1fr;
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: Zilla Slab, sans-serif;
  font-weight: 400;
  display: grid;
}

header {
  grid-area: h;
  padding: 20px 0 20px 5%;
  display: flex;
}

header > h1 {
  margin-left: 2%;
}

footer {
  text-align: center;
  grid-area: f;
  padding: 20px 0;
}

main {
  flex-wrap: wrap;
  grid-area: m;
  justify-content: space-around;
  display: flex;
}

.eo {
  background-color: red;
  width: 30vw;
  height: 30vw;
  margin: 10px;
}

#summary_table {
  width: 50vh;
  min-width: 20em;
  min-height: 10px;
}

a:link, a:visited {
  color: inherit;
}

/*# sourceMappingURL=index.7fa8cbe0.css.map */
